import { initializeApp } from 'firebase/app';
import firebase from 'firebase/compat/app';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const Firebase = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);

export const COLLECTION_USER_MODULE = 'userModule';
export const COLLECTION_USER_MODULE_USER_ATTRIBUTE = 'user';
export const COLLECTION_USER_MODULE_MODULE_ATTRIBUTE = 'module';
export const COLLECTION_COURSES = 'courses';
export const COLLECTION_USERS = 'users';

export default Firebase;