import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Firebase, { COLLECTION_USERS } from "../firebase";

function Login() {
  const [isSignedIn, setIsSignedIn] = useState(false);

  const navigate = useNavigate();

  const auth = getAuth(Firebase);
  const firestore = getFirestore(Firebase);

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => {
        const userRef = doc(firestore, COLLECTION_USERS, auth.currentUser.email);
        userRef.get().then((userData) => {
          if (!userData.exists) {
            userRef.set({
              courses: []
            }).then();
          }
        });
      }
    }
  };

  useEffect(() => {
    const unregisterAuthObserver =
      onAuthStateChanged(auth, (user) => {
        setIsSignedIn(!!user);
      });
    return unregisterAuthObserver; // Make sure we un-register Firebase observers when the component unmounts.
  }, [auth]);

  if (isSignedIn) {
    navigate(-1)
  } else return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />;
}

export default Login;
