import React from "react";
import { Row, Col } from "react-bootstrap";
import Resume from "../images/Resume.jpg";

function About() {
  return (
    <Row>
      <Col md="7">
        <strong>Hey there! Thanks for checking out my About page.</strong>{" "}
        <br /> For the next few paragraphs, I will be trying to convince you
        that I'm qualified to help you develop your career. To start off, the
        first thing a hiring manager looks at for a new applicant is their
        resume, so I've put mine to the right for you.{" "}
        <i className="fas fa-arrow-right"></i>
        <br />
        But this is just the tip of the iceberg, check out my LinkedIn to see
        everything I've been doing!
        <br />
        <br />
        <strong>I am an engineer by trade.</strong> I graduated in May 2021 with
        a degree in computer science with honors after just three years, and in
        May 2022, I got my master's in computer science with an emphasis on
        Intelligent Systems. Yes, I built this website from scratch myself.{" "}
        <br />
        <strong>Coming at life from an engineering perspective</strong> is
        incredibly valuable, even if that's not your job per se. Engineers learn
        how to create systems, work smarter instead of harder, and iterate on
        past mistakes. This is the perspective I want to bring to you. <br />
        <strong>
          When I was 18, I interned at a very sought-after company as a software
          engineer.
        </strong>{" "}
        That is not something that happens by accident. I interned there a few
        more times, and I have now accepted a six-figure full-time return offer.
        I personally attribute this success to 30% technical skills and 70%
        people skills. How you present yourself can be the difference between a
        yes and a no, and I want to help you get that yes, in whatever you want
        to be doing (I can also help you figure that out{" "}
        <i className="fas fa-smile-wink"></i>). I mentored a student in the past
        through the internship search process, and he ended up with an offer
        after only 3 months of our partnership. While I can't guarantee the same
        result, I want to try to do the same for you and your career.
      </Col>
      <a
        className="col-md-5"
        style={{ maxHeight: 500 }}
        target="_blank"
        rel="noreferrer"
        href="https://caitlin.tibbettsfamily.com/Resume.pdf"
      >
        <img
          src={Resume}
          alt="Caitlin's resume."
          className="img-fluid"
          style={{ height: "100%" }}
        />
      </a>
    </Row>
  );
}

export default About;
