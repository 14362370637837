import React, { useEffect, useState } from "react";
import { Container, Navbar, Nav, Col, Row } from "react-bootstrap";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Firebase from "../firebase";

import Footer from "./footer";

function Page(props) {
  const [isSignedIn, setIsSignedIn] = useState(false);

  const auth = getAuth(Firebase);

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver =
      onAuthStateChanged(auth, (user) => {
        setIsSignedIn(!!user);
      });
    return unregisterAuthObserver; // Make sure we un-register Firebase observers when the component unmounts.
  }, [auth]);

  return (
    <Container fluid>
      <Navbar expand="sm">
        <Container>
          <Navbar.Brand href="/" style={{ fontSize: 32 }}>
            Coaching Casual
          </Navbar.Brand>
          <Nav className="me-auto justify-content-end">
            {!isSignedIn ? (
              <Nav.Link href="/login">
                <button type="button" className="btn btn-primary">
                  Login/Sign Up
                </button>
              </Nav.Link>
            ) : (
              <Container className="d-flex">
                <Row className="align-items-center">
                  <Col md="auto">
                    <div>Hey, {auth.currentUser.displayName} 👋</div>
                  </Col>
                  <Col md="auto">
                    <Nav.Link
                      onClick={() => {
                        setIsSignedIn(false);
                        auth.signOut();
                      }}
                    >
                      <button type="button" className="btn btn-primary">
                        Sign Out
                      </button>
                    </Nav.Link>
                  </Col>
                  {auth.currentUser.email === 'caitlin@coachingcasual.com' &&
                    <Col md="auto">
                      <Nav.Link
                        href="/admin"
                      >
                        <button type="button" className="btn btn-primary">
                          Admin
                        </button>
                      </Nav.Link>
                    </Col>
                  }
                </Row>
              </Container>
            )}
          </Nav>
        </Container>
      </Navbar>
      <Container>{props.children}</Container>
      <hr />
      <Footer />
    </Container>
  );
}

export default Page;
