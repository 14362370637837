import React from "react";
import { Row, Col, Container } from "react-bootstrap";

function Footer() {
  return (
    <Container fluid>
      <Row>
        <Col md="4"/>
        <Col md="4">
          <Row>
            <Col style={{ textAlign: "center" }}>
              <a href="mailto:caitlin@coachingcasual.com">
                <i className="fas fa-envelope-square"></i>
              </a>
            </Col>
            <Col style={{ textAlign: "center" }}>
              <a href="https://caitlinmtibbetts.medium.com/">
                <i className="fab fa-medium"></i>
              </a>
            </Col>
            <Col style={{ textAlign: "center" }}>
              <a href="https://www.linkedin.com/in/caitlin-tibbetts/">
                <i className="fab fa-linkedin"></i>
              </a>
            </Col>
            <Col style={{ textAlign: "center" }}>
              <a href="https://github.com/caitlin-tibbetts">
                <i className="fab fa-github"></i>
              </a>
            </Col>
          </Row>
        </Col>
        <Col md="4"/>
      </Row>
    </Container>
  );
}

export default Footer;
