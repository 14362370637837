import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { getFirestore, getDoc, doc, setDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Firebase, { COLLECTION_USERS, COLLECTION_COURSES } from "../firebase";

const paypalOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
};

function Payment() {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [isCourse, setIsCourse] = useState(true);
    const [courseData, setCourseData] = useState();
    const [userData, setUserData] = useState();

    const { courseId } = useParams();

    const navigate = useNavigate();

    const auth = getAuth(Firebase);
    const firestore = getFirestore(Firebase);

    useEffect(() => {
        const unregisterAuthObserver =
            onAuthStateChanged(auth, (user) => {
                setIsSignedIn(!!user);
            });
        getDoc(doc(firestore, COLLECTION_COURSES, courseId)).then((course) => {
            if (course.exists) {
                setIsCourse(true);
                setCourseData(course.data());
                const currentUser = auth.currentUser;
                if (currentUser === null) {
                    setIsAuthorized(false);
                    setIsLoading(false);
                } else {
                    getDoc(doc(firestore, COLLECTION_USERS, currentUser.email)).then((user) => {
                        setIsAuthorized(_.findIndex(user.data().courses, (userCourse) => {
                            return userCourse.id === course.id
                        }) > -1);
                        setUserData(user.data());
                        setIsLoading(false);
                    })
                }
            } else {
                setIsCourse(false);
                setIsLoading(false);
            }
        });
        return unregisterAuthObserver;
    }, [firestore, auth, courseId]);

    const createOrder = (_, actions) => {
        return actions.order
            .create({
                purchase_units: [
                    {
                        description: courseId,
                        amount: {
                            currency_code: "USD",
                            value: 40,
                        },
                    },
                ],
                application_context: {
                    shipping_preference: "NO_SHIPPING",
                },
            })
            .then((orderID) => {
                return orderID;
            });
    };

    const onApprove = (_, actions) => {
        return actions.order.capture().then(() => {
            setDoc(doc(firestore, COLLECTION_USERS, auth.currentUser.email), {
                ...userData,
                courses: [...userData.courses, doc(firestore, COLLECTION_COURSES, courseId)]
            });
            navigate(`/course/${courseId}`);
        });
    };

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (!isCourse) {
        return <h1>Oops, no course here! Did you get sent a bad link?</h1>;
    }

    if(!isSignedIn) {
        return <p>Thank you for your interest in this course! Please sign in before getting access.</p>
    }

    if(isAuthorized) {
        return <p>You already have access to this course! Click <Link to={`/course/${courseId}`}>here</Link> to get started.</p>
    }

    return (
        <PayPalScriptProvider options={paypalOptions}>
            <h1>{courseData.title}</h1>
            <p>$40 | Released: {new Date(courseData.releaseDate.seconds * 1000).toLocaleDateString("en-US")} | <i>{courseData.modules.length} {courseData.modules.length === 1 ? "module" : "modules"}</i></p>
            <p>{courseData.description}</p>
            <PayPalButtons style={{ layout: "vertical" }} createOrder={createOrder} onApprove={onApprove} onError={(err) => {
                alert(`There was an error with your payment, please try again.\n${err}`)
            }} />
        </PayPalScriptProvider>
    );
}

export default Payment;
