import React, { useState, useEffect } from "react";
import _ from "lodash"
import { Link, useParams } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, getDoc, doc } from "firebase/firestore";

import Firebase, { COLLECTION_COURSES, COLLECTION_USERS } from "../firebase";

import Module from "../components/module";

function Course() {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [isCourse, setIsCourse] = useState(true);
    const [courseData, setCourseData] = useState();

    const { courseId } = useParams();

    const auth = getAuth(Firebase);
    const firestore = getFirestore(Firebase);

    useEffect(() => {
        const unregisterAuthObserver =
            onAuthStateChanged(auth, (user) => {
                setIsSignedIn(!!user);
            });
        getDoc(doc(firestore, COLLECTION_COURSES, courseId)).then((course) => {
            if (course.exists) {
                setIsCourse(true);
                setCourseData({id: course.id, ...course.data()});
                const currentUser = auth.currentUser;
                if (currentUser === null) {
                    setIsAuthorized(false);
                    setIsLoading(false);
                } else {
                    getDoc(doc(firestore, COLLECTION_USERS, currentUser.email)).then((user) => {
                        setIsAuthorized(_.findIndex(user.data().courses, (userCourse) => {
                            return userCourse.id === course.id
                        }) > -1);
                        setIsLoading(false);
                    })
                }
            } else {
                setIsCourse(false);
                setIsLoading(false);
            }
        });
        return unregisterAuthObserver;
    }, [courseId, auth, firestore]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (!isCourse) {
        return <h1>Oops, no course here! Did you get sent a bad link?</h1>;
    }

    return (
        <div>
            <div>
                <h1><strong>{courseData.title}</strong></h1>
                <p>Released: {new Date(courseData.releaseDate.seconds * 1000).toLocaleDateString("en-US")} | <i>{courseData.modules.length} {courseData.modules.length === 1 ? "module" : "modules"}</i></p>
                <p>{courseData.description}</p>
                {isSignedIn ? (
                    _.map(courseData.prereleaseModules, (moduleRef, index) => {
                        return <Module key={index} index={-1} moduleRef={moduleRef}/>
                    })
                ) : <p>Log in to check out the free modules for this course!</p>}
                <hr/>
                {isSignedIn && isAuthorized ? (
                    _.map(courseData.modules, (moduleRef, index) => {
                        return <Module key={index} index={index} moduleRef={moduleRef}/>
                    })
                ) : (<Link to={`/payment/${courseId}`}><button type="button" className="btn btn-primary">
                    Join Now! ($40)
                </button></Link>)}
            </div>
        </div>
    );
}

export default Course;
