import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { Container, Col, Row } from "react-bootstrap";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, setDoc, collection, getDocs } from "firebase/firestore";

import Firebase, { COLLECTION_COURSES } from "../firebase";

import ModuleEdit from "../components/moduleEdit";

function CourseTitle(props) {
    const [courseTitle, setCourseTitle] = useState(props.courseData.title);
    const [editingTitle, setEditingTitle] = useState(false);

    const firestore = getFirestore(Firebase);

    if (editingTitle) {
        return <div>
            <input type="text" value={courseTitle} onChange={(event) => {
                setCourseTitle(event.target.value);
            }} /><button type="button" className="btn btn-primary" onClick={() => {
                setDoc(doc(firestore, COLLECTION_COURSES, props.courseData.id), {
                    ...props.courseData,
                    title: courseTitle
                }).then(() => {
                    setEditingTitle(false);
                });
            }}>Save</button>
        </div>
    } else {
        return <div onClick={() => {
            setEditingTitle(true);
        }}>{courseTitle}</div>;
    }
}

function Admin() {
    const [isLoading, setIsLoading] = useState(true);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [courseList, setCourseList] = useState([]);
    const [coursePick, setCoursePick] = useState();

    const auth = getAuth(Firebase);
    const firestore = getFirestore(Firebase);

    useEffect(() => {
        const unregisterAuthObserver =
            onAuthStateChanged(auth, (user) => {
                setIsSignedIn(!!user);
            });
        getDocs(collection(firestore, COLLECTION_COURSES)).then((courses) => {
            setCourseList(_.map(courses.docs, (course) => ({ id: course.id, ...course.data() })));
            setIsLoading(false);
        });
        return unregisterAuthObserver;
    }, [firestore, auth]);

    if (!isSignedIn || auth.currentUser.email !== 'caitlin@coachingcasual.com') {
        return <p>Are you sure you have the right link? There's nothing here.</p>
    }

    if (isLoading) {
        return <p>Loading...</p>
    }

    return (
        <div>
            <Row>
                <Col md={12}>
                    <h1>Welcome to the Admin page.</h1>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    {!coursePick ? <p>Pick a course on the right.</p> : _.map(coursePick.modules, (moduleRef, index) => (<ModuleEdit key={index} index={index} moduleRef={moduleRef} />))}
                </Col>
                <Col md={4}>
                    <Container>
                        {_.map(courseList, (course, index) => (<div key={index}><Row><Col md={10}><CourseTitle courseData={course} /></Col><Col md={2}><button type="button" className="btn btn-transparent-bg" onClick={() => {
                            setCoursePick(course);
                        }}><i className="fas fa-pen" /></button></Col></Row><hr /></div>))}
                    </Container>
                </Col>
            </Row>
        </div>
    );
}

export default Admin;
