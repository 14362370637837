import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { getFirestore, getDoc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import ReactMarkdown from 'react-markdown'

import Firebase from "../firebase";

function ModuleEdit(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [closed, setClosed] = useState(true);
    const [isModule, setIsModule] = useState(false);
    const [moduleData, setModuleData] = useState();
    const [moduleContent, setModuleContent] = useState("");
    const [moduleTitle, setModuleTitle] = useState("");
    const [isPrerelease, setIsPrerelease] = useState(false);

    const [video, setVideo] = useState();
    const [percent, setPercent] = useState(0);

    const [editingTitle, setEditingTitle] = useState(false);

    const firestore = getFirestore(Firebase);
    const storage = getStorage(Firebase);

    useEffect(() => {
        getDoc(props.moduleRef).then((module) => {
            if (module === null) {
                setIsModule(false);
            } else {
                setIsModule(true);
                setModuleData({ id: module.id, ...module.data() });
                setModuleContent(module.data().content);
                setModuleTitle(module.data().title);
            }
            setIsLoading(false);
        });
    }, [props.moduleRef, firestore])

    if (!isModule) {
        return <h2>No module here! There must be an error.</h2>;
    }

    if (isLoading) {
        return <p>Loading...</p>;
    }

    function handleUpload() {
        if (!video) {
            alert("No video chosen for upload.")
        }

        const uploadTask = uploadBytesResumable(ref(storage, `${moduleData.id}.mp4`), video);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );

                // update progress
                setPercent(percent);
            },
            (err) => console.log(err)
        );
    }

    return (
        <div className="card">
            <Container>
                <Row className="my-auto">
                    <Col md="auto">
                        {!editingTitle ? <h2 onClick={() => {
                            setEditingTitle(true);
                        }}>{props.index + 1}. {moduleTitle}</h2> : <div><input type="text" value={moduleTitle} onChange={(event) => {
                            setModuleTitle(event.target.value);
                        }} /><button type="button" className="btn btn-primary" onClick={() => {
                            setDoc(props.moduleRef, {
                                title: moduleTitle,
                                content: moduleData.content
                            }).then(() => {
                                setEditingTitle(false);
                            });
                        }}>Save</button></div>}
                    </Col>
                    <Col>
                        <button type="button" className="btn btn-transparent-bg" onClick={() => {
                            setClosed(!closed);
                        }}>
                            <h2>{closed ? "+" : "–"}</h2>
                        </button>
                    </Col>
                </Row>
                {!closed && <Row><Col><input type="file" accept="video/mp4" onChange={(event) => {
                    setVideo(event.target.files[0]);
                }} /></Col><Col><button type="button" className="btn btn-primary" onClick={handleUpload}>Upload</button></Col>{percent > 0 && <Col>{percent}%</Col>}</Row>}
                {!closed && <Row><Col><textarea style={{ width: "100%" }} value={moduleContent} onChange={(event) => {
                    setModuleContent(event.target.value);
                }} /></Col></Row>}
                {!closed && <Row><Col><ReactMarkdown>{moduleContent}</ReactMarkdown></Col></Row>}
                {!closed && <p><input className="checkbox" type="checkbox" checked={isPrerelease} onChange={() => {
                            setIsPrerelease(!isPrerelease);
                        }} /> Prerelease Module?</p>}
                {!closed && <Row><Col><button type="button" className="btn btn-primary" onClick={() => {
                    setDoc(props.moduleRef, {
                        title: moduleData.title,
                        content: moduleContent,
                        isPrerelease: isPrerelease
                    }).then();
                }}>Save</button></Col></Row>}
            </Container>
        </div>
    )
}

export default ModuleEdit;