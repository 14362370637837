import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./styles/css/main.css";
import "./styles/css/all.css";

import Page from "./components/page";

import Home from "./pages/home";
import Login from "./pages/login";
import About from "./pages/about";
import Course from "./pages/course";
import Payment from "./pages/payment";
import Admin from "./pages/admin";

function App() {
  return (
    <Router>
      <Page>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/about" element={<About />} />
          <Route path="/course/:courseId" element={<Course />} />
          <Route path="/payment/:courseId" element={<Payment />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Page>
    </Router>
  );
}

export default App;
