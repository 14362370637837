import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, doc, query, getDocs, getDoc, setDoc, where, addDoc } from 'firebase/firestore'
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import ReactPlayer from "react-player";

import Firebase, { COLLECTION_USER_MODULE, COLLECTION_USER_MODULE_USER_ATTRIBUTE, COLLECTION_USER_MODULE_MODULE_ATTRIBUTE } from '../firebase';

function Module(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [closed, setClosed] = useState(true);
    const [isModule, setIsModule] = useState(false);
    const [moduleData, setModuleData] = useState();

    const [userModuleId, setUserModuleId] = useState("");
    const [done, setDone] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");

    const auth = getAuth(Firebase);
    const firestore = getFirestore(Firebase);
    const storage = getStorage(Firebase);

    useEffect(() => {
        getDoc(props.moduleRef).then((module) => {
            if (module === null) {
                setIsModule(false);
            } else {
                setIsModule(true);
                setModuleData({ id: module.id, ...module.data() });
                getDocs(query(collection(firestore, COLLECTION_USER_MODULE), where(COLLECTION_USER_MODULE_USER_ATTRIBUTE, "==", auth.currentUser.email), where(COLLECTION_USER_MODULE_MODULE_ATTRIBUTE, '==', props.moduleRef))).then((userModules) => {
                    if (userModules.docs.length > 0) {
                        setDone(userModules.docs[0].data().done);
                        setUserModuleId(userModules.docs[0].id);
                    } else {
                        addDoc(collection(firestore, COLLECTION_USER_MODULE), {
                            [COLLECTION_USER_MODULE_MODULE_ATTRIBUTE]: props.moduleRef,
                            [COLLECTION_USER_MODULE_USER_ATTRIBUTE]: auth.currentUser.email,
                            done: false
                        }).then((userModule) => {
                            setDone(false);
                            setUserModuleId(userModule.id);
                        });
                    }
                });
                getDownloadURL(ref(storage, `${module.id}.mp4`)).then((downloadUrl) => {
                    setVideoUrl(downloadUrl);
                }).catch((err) => {
                    // No video for this module yet
                    setVideoUrl("");
                });
            }
            setIsLoading(false);
        });
    }, [props.moduleRef, auth.currentUser.email, firestore, storage]);

    if (!isModule) {
        return <h2>No module here! There must be an error.</h2>;
    }

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="card" onClick={() => {
            setClosed(!closed);
        }}>
            <Container>
                <Row className="my-auto">
                    <Col xs={1} md={1} lg={1}>
                        <h2><input className="checkbox" type="checkbox" checked={done} onChange={() => {
                            setDone(!done);
                            setClosed(closed);
                            setDoc(doc(firestore, COLLECTION_USER_MODULE, userModuleId), {
                                [COLLECTION_USER_MODULE_USER_ATTRIBUTE]: auth.currentUser.email,
                                [COLLECTION_USER_MODULE_MODULE_ATTRIBUTE]: props.moduleRef,
                                done: !done
                            }).then();
                        }} /></h2>
                    </Col>
                    <Col md="auto">
                        {props.index > 0 ? <h2>{props.index + 1}. {moduleData.title}</h2> : <h2>{moduleData.title}</h2>}
                    </Col>
                    <Col>
                        <button type="button" className="btn btn-transparent-bg" onClick={() => {
                            setClosed(!closed);
                        }}>
                            <h2>{closed ? "+" : "–"}</h2>
                        </button>
                    </Col>
                </Row>
                {!closed && videoUrl.length > 0 && <Row><Col><ReactPlayer url={videoUrl} playing={true} controls={true} /></Col></Row>}
                {!closed && <Row><Col><ReactMarkdown>{moduleData.content}</ReactMarkdown></Col></Row>}
            </Container>
        </div>
    )
}

export default Module;