import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import portrait from "../images/portrait.jpg";

function Home() {
  return (
    <Row>
      <Col md="9">
        <p>
          <p>Hey there! I'm Caitlin Tibbetts, Founder of Coaching Casual.</p>
          <p>
            First and foremost, I am passionate about using my experiences to
            teach people. And now that I have graduated college, gotten my dream
            job, and organized my own finances, I want to do just that.
          </p>
          <p>
            My approach is <em>casual</em>. Instead of creating unnecessary
            stress with huge changes immediately, I will work with you to create
            systems that allow you to achieve your goals in a way that is
            enjoyable, fulfilling, and enduring.
          </p>
          <p>
            <strong>
              Click my face to find out more about me. And don't forget to join{" "}
              <a href="http://eepurl.com/hF_yRr">the mailing list</a> too!
            </strong>
          </p>
        </p>
      </Col>
      <Link to="/about" className="col-md-3" style={{ maxHeight: 150 }}>
        <img
          src={portrait}
          alt="Caitlin, a white woman with brunette hair, in a striped shirt."
          className="img-fluid"
          style={{ borderRadius: "100%", height: "100%" }}
        />
      </Link>
    </Row>
  );
}

export default Home;
